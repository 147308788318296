export const appConfig = {
    drawerWidth: 240,
    titleBarHeight: 64,
    contentMinWidth: 600,
    contentMaxWidth: 1000,
    contentTopPadding: 40
}

export enum appRoutes {
    MARKET_DETAIL = '/market-detail/',
    MARKETS = '/markets',
    PROPERTIES = '/properties',
    PROPERTY_DETAIL = '/property-detail/',
    LEDGER = '/ledger',
    TRAVEL = '/travel',
    SETTINGS = '/settings',
    SETTINGS_METRICS = '/settings/metrics',
    SETTINGS_EXPENSES = '/settings/expenses',
    DASHBOARD = '/'
}