export enum Query {
    GET_MARKETS = 'getMarkets',
    GET_RECENT_MARKETS = 'getRecentMarkets',
    GET_RECENT_PROPERTIES = 'getRecentProperties',
    GET_MARKET_QUESTIONS = 'getMarketQuestions',
    GET_DEFAULT_EXPENSES = 'getDefaultExpenses',
    GET_PROPERTIES = 'getProperties',
    GET_LEDGER_ENTRIES = 'getLedgerEntries',
    GET_LEDGER_CATEGORIES = 'getLedgerCategories',
    GET_TRAVEL_LOGS = 'getTravelLogs'
}